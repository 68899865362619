import AddIcon from '@mui/icons-material/Add';
import CallMadeIcon from '@mui/icons-material/CallMade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { Alert, Backdrop, Breadcrumbs, Button, CircularProgress, Grid, IconButton, Link, ListItemIcon, Menu, MenuItem, MenuProps, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Fab from '@mui/material/Fab/Fab';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom/Zoom';
import { AssosiateFlightAndOrder, Display, FlightInfo, FunctionPrivilege, OrderInfo, OrderStatus, ReceiveStatus, UserInfo, createDefault, createInitData } from 'adoms-common-lib';
import axios from 'axios';
import { QRCodeCanvas } from 'qrcode.react';
import queryString from "query-string";
import React, { useEffect, useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles, withStyles } from 'tss-react/mui';
import { havePrivilege } from '../../../common/PrivilegeUtil';
import { useUserInfoContext } from '../../../common/UserContext';
import FlightStatusInfo from '../../../components/atoms/FlightStatusInfo';
import ReceiveStatusInfo from '../../../components/atoms/ReceiveStatusInfo';
import OrderDestinationInfo from '../../../components/molecule/OrderDestinationInfo';
import OrderGoodTable from '../../../components/molecule/OrderGoodTable';
import { FlightCreateDialog } from '../../../components/organisms/FlightCreateDialog';
import OperationMenuBar from '../../../components/organisms/OperationMenuBar';
import { APIConnector } from '../../../connector/APIConnector';

const drawerWidth = 260;

const useStyles = makeStyles()((theme: any) => ({
  griditem: {
  },

  root: {
    display: 'flex',
    backgroundColor: theme.palette.background.default
  },

  title: {
    flexGrow: 1,
    textAlign: 'center',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },

  table: {
    minWidth: 100,
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },

  /**
   * メニューヘッダー
   */
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },

  /**
   * 
   */
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },


  paper: {
    padding: '12px',
    textAlign: 'center',
    height: '100%',
  },
  errorMessage: {
    marginTop: theme.spacing(2),
    padding: '12px',
    textAlign: 'center',
    height: '100%',
  },
  orderInfoNoticePaper: {
    padding: '12px 0px 12px 0px',
    textAlign: 'center',
    height: '100%',
  },

  savefab: {
    position: 'fixed',
    marginTop: theme.spacing(-1),
    right: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  cancelfab: {
    position: 'fixed',
    marginTop: theme.spacing(-1),
    right: theme.spacing(24),
  }
}));



const StyledMenu = withStyles((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
), {
  paper: {
    border: '1px solid #d3d4d5',
  },
});

const StyledMenuItem = withStyles(MenuItem, (theme: any) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}));



/**
 * ステータスが到着以降であるかを確認する
 * @param order 
 */
function isArrivedOrLater(order: OrderInfo): boolean {
  if (order.status === OrderStatus.Arrivaled ||
    order.status === OrderStatus.ReturnInPreparation ||
    order.status === OrderStatus.ReturnInFlight ||
    order.status === OrderStatus.ReturnArrivingSoon ||
    order.status === OrderStatus.ReturnArrivaled ||
    order.status === OrderStatus.Completed) {
    return true;
  }
  return false;
}


/**
 * 詳細
 */
export default function OrderDetailView(props: any) {
  const { classes, cx } = useStyles();
  const navigate = useNavigate();
  const userInfoContext = useUserInfoContext();

  const location = useLocation();
  const [isOpen, setOpen] = React.useState(false);
  const qs = queryString.parse(location.search);
  const [orderID, setorderID] = React.useState<{ orderID: string }>(location.state == undefined ? { orderID: qs.orderID as string } : location.state as { orderID: string })
  const [order, setOrder] = React.useState(createInitData());
  const [orderReceiptImageBase64, setOrderReceiptImageBase64] = React.useState<string>('');
  const [assosiateFlightAndOrder, setAssosiateFlightAndOrder] = React.useState<AssosiateFlightAndOrder | undefined>(undefined);
  const [flight, setFlight] = React.useState(createDefault());

  const [receiveMenu, setReceiveMenu] = React.useState<null | HTMLElement>(null);
  const [isImageOnLoad, setIsImageOnload] = React.useState(false);

  //ステータスが変更された場合true
  const [isEdit, setEdit] = React.useState(false);

  const [isNewFlightDialogOpen, setNewFlightDialogOpen] = React.useState(false);
  const [isDisplayLoadingMark, setDisplayLoadingMark] = React.useState(false);

  const [isDisplayLoadingMarkForUserInfo, setDisplayLoadingMarkForUserInfo] = React.useState(false);
  const [userInfo, setUserInfo] = React.useState<UserInfo | undefined>(userInfoContext.userInfo);

  // 同じタイミングでエラーが発生する可能性があるため、上書きされないようにエラーメッセージ表示場所を分ける
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined)
  const [userErrorMessage, setUserErrorMessage] = React.useState<string | undefined>(undefined)

  const [orderForReset, setOrderForReset] = React.useState<OrderInfo | undefined>(undefined);
  const [assosiateFlightAndOrderForReset, setassosiateFlightAndOrderForReset] = React.useState<AssosiateFlightAndOrder | undefined>(undefined);
  const [flightForReset, setFlightForReset] = React.useState<FlightInfo | undefined>(createDefault());

  //配送依頼情報を取得
  useLayoutEffect(() => {
    const fetchData = async () => {
      let c: APIConnector = APIConnector.instance;
      //メモ：Lambdaコンテナ初回起動時、画像サイズによって読込が5秒くらいかかる
      let getOrderPromise = c.getOrderForOperation(orderID.orderID).then(orderInfo => {
        if (typeof orderInfo.receiptFileName !== 'undefined') {
          c.getReciept(orderID.orderID, orderInfo.receiptFileName!).then(response => {
            setIsImageOnload(true);
            setOrderReceiptImageBase64(response);
            setErrorMessage(undefined);
          }).catch(error => {
            setIsImageOnload(true);
            setErrorMessage("データを正常に読み込めませんでした。ページを再読み込みして操作をやりなおしてください。");
            console.log(error);
          });
        } else {
          setIsImageOnload(true);
        }
        return orderInfo;
      });
      let assosiateFlightAndOrderPromise = c.getFlightIdTiedToOrderId(orderID.orderID)
      let getFlightPromise = assosiateFlightAndOrderPromise.then(data => {
        if (data.length === 0) {
          return Promise.resolve(createDefault());
        }
        /*
        フライトとオーダーは多対多になる予定だが、
        現状オーダーに紐づけられるフライトは1個であるため、
        このようなコードとしている
        */
        return c.getFlight(data[0].flightId);
      });
      setDisplayLoadingMark(true);
      await Promise.all([getOrderPromise, assosiateFlightAndOrderPromise, getFlightPromise]).then(value => {
        setDisplayLoadingMark(false);
        let currentOrder = value[0];
        setOrder(currentOrder);
        const copyOrder = JSON.parse(JSON.stringify(currentOrder)) as OrderInfo;
        setOrderForReset(copyOrder);

        let currentAssosiateFlightAndOrder = value[1];
        setAssosiateFlightAndOrder(currentAssosiateFlightAndOrder[0]);
        if (typeof currentAssosiateFlightAndOrder[0] !== "undefined") {
          const copyAssosiateFlightAndOrder = JSON.parse(JSON.stringify(currentAssosiateFlightAndOrder[0])) as AssosiateFlightAndOrder;
          setassosiateFlightAndOrderForReset(copyAssosiateFlightAndOrder);
        }

        let currentFlight = value[2];
        setFlight(currentFlight);
        if (typeof currentAssosiateFlightAndOrder !== "undefined") {
          const copyFlight = JSON.parse(JSON.stringify(currentFlight)) as FlightInfo;
          setFlightForReset(copyFlight);
        }
        setErrorMessage(undefined);
      }).catch(error => {
        console.log("error: " + error);

        if (axios.isAxiosError(error)
          && typeof error.response !== "undefined"
          && error.response.status === 403) {
          handleToForbidden403Page();
        } else {
          setDisplayLoadingMark(false);
          setIsImageOnload(true);
          setErrorMessage("データを正常に読み込めませんでした。ページを再読み込みして操作をやりなおしてください。");
        }
      });
    }
    fetchData();
  }, []);

  /**
  * userInfoを取得する
  */
  useEffect(() => {
    if (userInfoContext.isError) {
      setUserErrorMessage("ユーザー情報が取得できませんでした。一部の機能が使えないことがあります。");
      setDisplayLoadingMarkForUserInfo(false);
    } else {
      setUserErrorMessage(undefined);
      if (typeof userInfoContext.userInfo === "undefined") {
        setDisplayLoadingMarkForUserInfo(true);
      } else {
        setDisplayLoadingMarkForUserInfo(false);
      }
    }
    setUserInfo(userInfoContext.userInfo);
  }, [userInfoContext]);

  /**
   * メニューバーが開かれたときのハンドラ
   */
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  /**
   * メニューバーが閉じられた時のハンドラ
   */
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleReceiveStatusClick = (event: React.MouseEvent<HTMLElement>) => {
    setReceiveMenu(event.currentTarget);
  };

  const handleReceiveStatusClose = () => {
    setReceiveMenu(null);
  };

  /**
   * 配送状況を変更するときのハンドラ
   * @param event 
   * @param newStatus 
   */
  const handleReceiveStatusChange = (event: React.MouseEvent<HTMLElement>, newStatus: ReceiveStatus) => {
    console.log(newStatus + ":" + event);
    setReceiveMenu(null);
    if (order.receiveStatus !== newStatus) {
      order.receiveStatus = newStatus;
      setEdit(true);
    }
  }


  /**
   * セーブするときのハンドラ
   */
  const handleSaveClick = () => {
    setEdit(false);
    setDisplayLoadingMarkForUserInfo(true);
    const fetchData = async () => {
      /**
       * dynamoDBにorderを保存
       */
      let apigw: APIConnector = APIConnector.instance;
      let result: Promise<void | OrderInfo> = apigw.putOrderChange(order);
      result.then((value: void | OrderInfo) => {
        /**
         * 戻りの配送依頼情報を初期値にセットする
         */
        let saveOrder = value as OrderInfo;
        setOrder(saveOrder);
        setErrorMessage(undefined);
        setDisplayLoadingMarkForUserInfo(false);
      }).catch((error) => {
        console.log("エラー:" + error);
        if (axios.isAxiosError(error)
          && typeof error.response !== "undefined"
          && error.response.status === 403) {
          setErrorMessage("権限がありません。");
        } else {
          setErrorMessage("保存できませんでした。ページを再読み込みして操作をやりなおしてください。");
        }
        setEdit(true);
        setDisplayLoadingMarkForUserInfo(false);
      });
    }
    fetchData();

  }

  /**
   * 次のページに遷移する際の処理
   * @param event 
   * @param navigate 
   */
  const handleGotoFlight = (event: any, order: OrderInfo) => {
    navigate(
      "/flightdetailview",
      {
        state: { id: assosiateFlightAndOrder?.flightId ?? "" },
      })
  }

  /**
 * ダイアログを開く
 * @param scrollType 
 */
  const handleClickNewFlightDialogOpen = () => {
    setNewFlightDialogOpen(true);
  };

  /**
   * ダイアログをクローズする
   */
  const handleNewFlightDialogClose = () => {
    setNewFlightDialogOpen(false);
  };

  /**
   * 403ページに遷移させる
   * @param navigate 
   */
  const handleToForbidden403Page = () => {
    navigate(
      "/forbidden403Page"
    )
  }

  /**
   * 元に戻すときのハンドラ
   */
  const handleCancelClick = async () => {
    setEdit(false);

    let copyOrder = JSON.parse(JSON.stringify(orderForReset)) as OrderInfo;
    setOrder(copyOrder);

    if (typeof assosiateFlightAndOrderForReset !== "undefined") {
      let copyAssosiateFlightAndOrder = JSON.parse(JSON.stringify(assosiateFlightAndOrderForReset)) as AssosiateFlightAndOrder ?? undefined;
      setAssosiateFlightAndOrder(copyAssosiateFlightAndOrder);
    }

    if (typeof flightForReset !== "undefined") {
      let copyFlight = JSON.parse(JSON.stringify(flightForReset)) as FlightInfo;
      setFlight(copyFlight);
    }
  }

  /**
   * フライト作成、またはフライト詳細に遷移するボタンを表示する
   */
  const flightCreateOrDisplayTableCell = () => {
    if (assosiateFlightAndOrder === undefined) {
      if (havePrivilege(FunctionPrivilege.PrivilegeFunctionCreateFlight, userInfo)) {
        return (
          <TableCell align="right">
            <Zoom in={true} style={{ transitionDelay: '500ms' }}>
              <Fab color="primary"
                aria-label="Add"
                size="small"
                onClick={handleClickNewFlightDialogOpen}>
                <AddIcon />
              </Fab>
            </Zoom>
          </TableCell>
        );
      } else {
        return (<TableCell align="right" />);
      }
    } else {
      return (
        <TableCell align="right">
          <IconButton
            aria-label="詳細"
            onClick={(e: any) => { handleGotoFlight(e, order) }}
            size="large">
            <CallMadeIcon />
          </IconButton>
        </TableCell >
      );
    };
  };

  return (
    <div className={classes.root}>
      <CssBaseline />

      {/**メニューバーを表示する */}
      <OperationMenuBar
        onChangeDrawerOpen={handleDrawerOpen}
        onChangeDrawerClose={handleDrawerClose}
        open={isOpen}
        title="配送管理"
        bgcolor="#00695f"
      />

      {/** コンテンツ部分 */}
      <main
        className={cx(classes.content, {
          /** メニューバーがオープン・クローズされたときのスタイルの変更*/
          [classes.contentShift]: isOpen,
        })}
      >
        <div className={classes.drawerHeader} />
        {
          /**
           * データが更新されている場合、保存ボタンを表示する
           */
          isEdit ?
            (
              <React.Fragment>
                <Zoom in={isEdit} style={{ transitionDelay: isEdit ? '500ms' : '0ms' }}>
                  <Button variant="contained" color="primary"
                    size="large"
                    className={classes.cancelfab}
                    startIcon={<ClearOutlinedIcon />}
                    onClick={handleCancelClick}
                    disabled={!isEdit}
                    style={{ zIndex: 1000 }}
                  >
                    元に戻す
                  </Button>
                </Zoom>
                <Zoom in={isEdit} style={{ transitionDelay: isEdit ? '500ms' : '0ms' }}>
                  <Button variant="contained" color="secondary"
                    size="large"
                    className={classes.savefab}
                    startIcon={<SaveIcon />}
                    onClick={handleSaveClick}
                    disabled={!isEdit}
                    style={{ zIndex: 3000 }}
                  >
                    変更を保存
                  </Button>
                </Zoom>
              </React.Fragment>
            ) : null
        }

        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href="/orderlistview" variant="h5">
            配送依頼一覧
          </Link>
          <Typography color="textPrimary" aria-current="page" variant="h5">
            配送依頼詳細
          </Typography>
        </Breadcrumbs>


        <Grid item xs={12} sm={12} className={classes.griditem}>
          {
            errorMessage ? (
              <Paper className={classes.errorMessage} elevation={1}>
                <Alert severity="error">{errorMessage}</Alert>
              </Paper>
            ) : (null)
          }
          {
            userErrorMessage ? (
              <Paper className={classes.errorMessage} elevation={1}>
                <Alert severity="error">{userErrorMessage}</Alert>
              </Paper>
            ) : (null)
          }
        </Grid>
        <br />
        <Grid container justifyContent="center" spacing={1} alignItems="stretch">
          <Grid item lg={6} md={6} sm={12} xs={12} className={classes.griditem}>
            <Paper className={classes.paper} elevation={1}>
              <Grid item xs={12} sm={12} className={classes.griditem}>
                <Typography className={classes.title} variant="h6" component="div">
                  配送情報
                </Typography>
                <TableContainer component={Paper} elevation={0}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableBody>
                      <TableRow >
                        <TableCell>フライト<br />希望日時</TableCell>
                        <TableCell component="th" scope="row">
                          <Typography variant="h5" component="div" color="secondary">{order.preferredDeliveryDate + " " + order.preferredDeliveryTime}</Typography>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      <TableRow >
                        <TableCell>配送依頼日</TableCell>
                        <TableCell component="th" scope="row">
                          <Typography variant="h5" component="div" color="secondary">{Display.getDateStringFromISO8601(order.orderDate)}</Typography>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      <TableRow >
                        <TableCell>区間</TableCell>
                        <TableCell component="th" scope="row">
                          <Typography variant="h5" component="div" color="secondary">{order.departure.name + " ⇒ " + order.arrival.name}</Typography>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      <TableRow >
                        <TableCell component="th" scope="row" style={{ width: 130 }}>パートナーID</TableCell>
                        <TableCell >
                          <Typography variant="h5" component="div" color="secondary">
                            {order == undefined ? "未設定" : order.businessPartnerId}
                          </Typography>
                        </TableCell>
                        <TableCell rowSpan={2}>
                          <div>
                            <QRCodeCanvas value={order.orderID} />
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow >
                        <TableCell component="th" scope="row" style={{ width: 130 }}>確認番号</TableCell>
                        <TableCell >
                          <Typography variant="h5" component="div" color="secondary">
                            {order == undefined ? "未設定" : order.orderID}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow >
                        <TableCell component="th" scope="row" style={{ width: 130 }}>外部連携ID</TableCell>
                        <TableCell >
                          <Typography variant="h5" component="div" color="secondary">
                            {order === undefined || order.externalOrderID === undefined ? "未設定" : order.externalOrderID}
                          </Typography>
                        </TableCell>
                        <TableCell></TableCell>

                      </TableRow>
                      <TableRow >
                        <TableCell>配送物の<br />配送状況</TableCell>
                        <TableCell component="th" scope="row">
                          <ReceiveStatusInfo status={order.receiveStatus} />
                        </TableCell>
                        <TableCell align="right">
                          <Zoom in={true} style={{ transitionDelay: '500ms' }}>
                            <Fab color="primary" aria-label="Edit" size="small" onClick={handleReceiveStatusClick}>
                              <EditIcon />
                            </Fab>
                          </Zoom>
                          <StyledMenu
                            id="customized-menu"
                            anchorEl={receiveMenu}
                            keepMounted
                            open={Boolean(receiveMenu)}
                            onClose={handleReceiveStatusClose}
                          >
                            <StyledMenuItem selected={order.receiveStatus === ReceiveStatus.WaitingForPickup} onClick={(e: any) => { handleReceiveStatusChange(e, ReceiveStatus.WaitingForPickup) }}>
                              <ListItemIcon>
                                <ReceiveStatusInfo status={ReceiveStatus.WaitingForPickup} />
                              </ListItemIcon>
                            </StyledMenuItem>
                            <StyledMenuItem selected={order.receiveStatus === ReceiveStatus.Preparation} onClick={(e: any) => { handleReceiveStatusChange(e, ReceiveStatus.Preparation) }}>
                              <ListItemIcon>
                                <ReceiveStatusInfo status={ReceiveStatus.Preparation} />
                              </ListItemIcon>
                            </StyledMenuItem>
                            <StyledMenuItem selected={order.receiveStatus === ReceiveStatus.Loaded} onClick={(e: any) => { handleReceiveStatusChange(e, ReceiveStatus.Loaded) }}>
                              <ListItemIcon>
                                <ReceiveStatusInfo status={ReceiveStatus.Loaded} />
                              </ListItemIcon>
                            </StyledMenuItem>
                            <StyledMenuItem selected={order.receiveStatus === ReceiveStatus.Received} onClick={(e: any) => { handleReceiveStatusChange(e, ReceiveStatus.Received) }}>
                              <ListItemIcon>
                                <ReceiveStatusInfo status={ReceiveStatus.Received} />
                              </ListItemIcon>
                            </StyledMenuItem>
                            <StyledMenuItem selected={order.receiveStatus === ReceiveStatus.Cancel} onClick={(e: any) => { handleReceiveStatusChange(e, ReceiveStatus.Cancel) }}>
                              <ListItemIcon>
                                <ReceiveStatusInfo status={ReceiveStatus.Cancel} />
                              </ListItemIcon>
                            </StyledMenuItem>
                          </StyledMenu>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <br />
              </Grid>
              <Grid item xs={12} sm={12} className={classes.griditem}>
                <Typography className={classes.title} variant="h6" component="div">
                  フライト情報
                </Typography>
                <TableContainer component={Paper} elevation={0}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableBody>
                      <TableRow >
                        <TableCell component="th" scope="row" style={{ width: 130 }}>フライトID</TableCell>
                        <TableCell>
                          {assosiateFlightAndOrder === undefined ?
                            (<Alert severity="warning">フライト未設定</Alert>) : (
                              <Typography variant="h5" component="div" color="secondary">{assosiateFlightAndOrder.flightId}</Typography>)}
                        </TableCell>
                        {flightCreateOrDisplayTableCell()}
                      </TableRow>
                      <TableRow >
                        <TableCell component="th" scope="row">フライト<br />ステータス</TableCell>
                        <TableCell>
                          <FlightStatusInfo status={flight.status} />
                        </TableCell>
                        <TableCell align="right" />
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

                <FlightCreateDialog
                  isFlightCreateDialogOpen={isNewFlightDialogOpen}
                  setFlightCreateDialogOpen={setNewFlightDialogOpen}
                  userInfo={userInfo}
                  orderInfo={order}
                />
              </Grid>
            </Paper>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12} className={classes.griditem}>
            <Paper className={classes.paper} elevation={1}>
              <Typography className={classes.title} variant="h6" component="div">
                受け取り人情報
              </Typography>
              <OrderDestinationInfo order={order} />
              <Typography className={classes.title} variant="h6" component="div">
                配送物
              </Typography>
              <OrderGoodTable
                order={order}
                setOrder={setOrder}
                setEdit={setEdit} />
              <Grid item xs={12} sm={12} className={classes.griditem}>
                <Typography className={classes.title} variant="h6" component="div">
                  注文情報
                </Typography>
                <Paper className={classes.orderInfoNoticePaper} elevation={0}>
                  {isImageOnLoad && typeof order.receiptFileName === 'undefined' ?
                    <Alert severity="info">注文情報が指定されていません</Alert> :
                    null
                  }
                  {isImageOnLoad ?
                    <img
                      id="receiptImage"
                      src={orderReceiptImageBase64}
                      style={
                        navigator.userAgent.match(/iPhone|Android.+Mobile/) ?
                          { width: '100%' } :
                          { width: '50%' }
                      }
                    /> :
                    <CircularProgress />
                  }
                </Paper>
              </Grid>
            </Paper>
          </Grid>

          <Backdrop className={classes.backdrop} open={isDisplayLoadingMark || isDisplayLoadingMarkForUserInfo}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Grid>
      </main >
    </div >
  );
}