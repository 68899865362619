import React, { useEffect, useState } from "react";
import { Dialog, Button, MobileStepper, styled } from "@mui/material";
import { Port, createEmptyPort } from "adoms-common-lib";
import PortInformationContent from "../molecule/PortInformationContent";
import PortAddressDialog from "../molecule/PortAddressContent";
import { APIConnector } from "../../connector/APIConnector";
import axios from "axios";

// propsの型を定義
type PortCreateDialogporps = {
  isOpen: boolean;
  closeHandler: () => void;
  allPortList?: Port[];
  setEditPortList: React.Dispatch<React.SetStateAction<boolean>>;
};

// MobileStepperのスタイル
const PortMobileStepper = styled(MobileStepper)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
}));

// ポート情報ダイアログ
const PortCreateDialog: React.FC<PortCreateDialogporps> = (props) => {
  const [newPort, setNewPort] = useState<Port>(createEmptyPort());
  const [activeStep, setActiveStep] = useState(0);
  const [isNextDisable, setIsNextDisable] = useState(true);
  const [isConfirmDisable, setIsConfirmDisable] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const handleClose = () => {
    setErrorMessage(undefined);
    props.closeHandler();
  };

  // 次へを押したとき
  const nextHandler = () => {
    setActiveStep((prevSteps) => prevSteps + 1);
  };

  // 戻るを押したとき
  const backHandler = () => {
    setActiveStep((prevSteps) => prevSteps - 1);
  };

  // ダイアログが閉じるたびにstate初期化
  useEffect(() => {
    if (!props.isOpen) {
      setActiveStep((prevSteps) => (prevSteps = 0));
      setNewPort(createEmptyPort());
      setIsNextDisable(() => true);
    }
  }, [props.isOpen]);

  // 登録処理
  const handleConfirm = (event: any) => {
    if (newPort) {
      // ソートキーを設定する
      let maxSortkey = 0;

      if (props.allPortList) {
        for (let port of props.allPortList) {
          if (maxSortkey < port.sortkey) {
            maxSortkey = port.sortkey;
          }
        }
      }

      newPort.sortkey = maxSortkey + 1;

      let apigw: APIConnector = APIConnector.instance;
      let result: Promise<void | string> = apigw.createPort(newPort);

      result.then(() => {
        props.closeHandler();
        props.setEditPortList(true);
      }).catch((error) => {
        console.log(error);
        if (axios.isAxiosError(error) && typeof error.response !== "undefined" && error.response.status === 403) {
          setErrorMessage("権限がありません");
        } else {
          setErrorMessage("ポート情報を登録できませんでした");
        }
      });
    }
  };

  return (
    <Dialog
      open={props.isOpen}
      onClose={() => {
        handleClose();
      }}
      sx={{ width: "auto" }}
      aria-labelledby="responsive-dialog-title"
    >
      {/* ポート情報 */}
      {activeStep === 0 && (
        <PortInformationContent
          newPort={newPort}
          setNewPort={setNewPort}
          setIsNextDisable={setIsNextDisable}
          allPortList={props.allPortList}
        />
      )}

      {/* ポート住所 */}
      {activeStep === 1 && (
        <PortAddressDialog
          port={newPort}
          setPort={setNewPort}
          setIsConfirmDisable={setIsConfirmDisable}
          errorMessage={errorMessage}
        />
      )}

      {/* ダイアログボタン関連 */}
      <PortMobileStepper
        variant="dots"
        steps={2}
        position="static"
        activeStep={activeStep}
        nextButton={
          activeStep !== 1 ? (
            <Button
              color="primary"
              size="small"
              onClick={nextHandler}
              disabled={isNextDisable}
            >
              次へ
            </Button>
          ) : (
            <Button
              color="secondary"
              size="small"
              onClick={handleConfirm}
              disabled={isConfirmDisable}
            >
              確定
            </Button>
          )
        }
        backButton={
          <Button
            color="primary"
            size="small"
            onClick={backHandler}
            disabled={activeStep === 0}
          >
            戻る
          </Button>
        }
      />
    </Dialog>
  );
};

export default PortCreateDialog;