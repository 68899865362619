import LinkIcon from '@mui/icons-material/Link';
import { Alert, Backdrop, Button, CircularProgress, CssBaseline, Grid, Paper, TableContainer, Typography } from '@mui/material';
import { MapURLInformation } from 'adoms-common-lib';
import React, { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { SixSecondsDisplaySnackbar } from '../../../components/atoms/SixSecondsDisplaySnackbar';
import { CreateMapURLDialog } from '../../../components/organisms/CreateMapURLDialog';
import { MapURLListTable } from '../../../components/organisms/MapURLListTable';
import OperationMenuBar from '../../../components/organisms/OperationMenuBar';
import { APIConnector } from '../../../connector/APIConnector';

const drawerWidth = 260;
const useStyles = makeStyles()((theme: any) => ({
    root: {
        display: 'flex',
        backgroundColor: theme.palette.background.default
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
}))


/**
 * ドローン位置情報表示マップURL作成
 * MapURLInformation側SaaS対応完了次第相手方と統合する
 */
export default function MapURLListView() {

    const { classes, cx } = useStyles();
    const [isOpen, setOpen] = React.useState(false);
    const [mapURLInfoList, setMapURLInfoList] = React.useState<MapURLInformation[]>([]);
    const [mapURLInfoError, setMapURLInfoError] = React.useState<string>();
    const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false);
    const [isCreateMapURLDialogOpen, setCreateMapURLDialogOpen] = React.useState<boolean>(false);
    const [isCreateMapURL, setCreateMapURL] = React.useState<boolean>(false);
    const [isDisplayLoadingMark, setDisplayLoadingMark] = React.useState<boolean>(false);

    /**
     * マップURL情報リストを取得する
     */
    useEffect(() => {
        const fetchData = async () => {
            const c: APIConnector = APIConnector.instance;
            setDisplayLoadingMark(true);
            await c.getMapURLInfoList().then(async (mapURLInfoList: MapURLInformation[]) => {
                setMapURLInfoError(undefined);
                setMapURLInfoList(mapURLInfoList);
                setCreateMapURL(false);
                setDisplayLoadingMark(false);
            }).catch((error) => {
                console.log(error);
                setMapURLInfoError("URL一覧が取得できませんでした");
                setDisplayLoadingMark(false);
            });
        };
        fetchData();
    }, [isCreateMapURL]);

    /**
     * メニューバーが開かれたときのハンドラ
     */
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    /**
     * メニューバーが閉じられた時のハンドラ
     */
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleClickCreateMapURLDialogClose = () => {
        setCreateMapURLDialogOpen(false);
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            {/**メニューバーを表示する */}
            <OperationMenuBar
                onChangeDrawerOpen={handleDrawerOpen}
                onChangeDrawerClose={handleDrawerClose}
                open={isOpen}
                title="マップURL一覧"
            />
            {/** コンテンツ部分 */}
            <main
                className={cx(classes.content, {
                    /** メニューバーがオープン・クローズされたときのスタイルの変更*/
                    [classes.contentShift]: isOpen,
                })}
            >
                <div className={classes.drawerHeader} />
                <Grid container direction="row">
                    <Typography style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        作成したマップURLの詳細情報を確認できます。
                    </Typography>
                    <div style={{ flexGrow: 1 }} />
                    <Button color="primary"
                        variant='contained'
                        size='small'
                        onClick={() => setCreateMapURLDialogOpen(true)}
                        sx={{ margin: "10px", minWidth: 0, marginRight: 0 }}>
                        <LinkIcon />
                    </Button>
                </Grid>
                <Grid container spacing={1}>
                    {mapURLInfoError ?
                        <Grid item sm={12} md={12} lg={12}>
                            <Paper elevation={1} sx={{ padding: 1 }}>
                                <Alert severity="error">{mapURLInfoError}</Alert>
                            </Paper>
                        </Grid> : undefined}
                    <Grid item sm={12} md={12} lg={12}>
                        <TableContainer component={Paper}>
                            <MapURLListTable
                                mapURLInfoList={mapURLInfoList}
                                setSnackBarOpen={setSnackbarOpen} />
                        </TableContainer>
                    </Grid>
                </Grid>
                <SixSecondsDisplaySnackbar
                    message={"URLをコピーしました"}
                    snackbarOpen={snackbarOpen}
                    setSnackbarOpen={setSnackbarOpen}
                />
                <CreateMapURLDialog
                    isCreateMapURLDialogOpen={isCreateMapURLDialogOpen}
                    handleClickCreateMapURLDialogClose={handleClickCreateMapURLDialogClose}
                    setCreateMapURL={setCreateMapURL} />

                <Backdrop
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}
                    open={isDisplayLoadingMark}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </main >
        </div >
    )
}