import React, { useEffect, useState } from "react";
import { Alert, Checkbox, Chip, ListItemText, Divider, MenuItem, DialogContent, Select, Tooltip, DialogTitle, TextField, Grid, FormControl, InputLabel, } from "@mui/material";
import FlagIcon from "@mui/icons-material/Flag";
import { BusinessPartnerInfo, OperationPartnerInfo, PartnerKind, Port, PortValidator, UserInfo, PortType } from "adoms-common-lib";
import { useUserInfoContext } from "../../common/UserContext";

// propsの型を定義
type PortInformationProps = {
  newPort: Port;
  setNewPort: React.Dispatch<React.SetStateAction<Port>>;
  setIsNextDisable: React.Dispatch<React.SetStateAction<boolean>>;
  allPortList?: Port[];
};

// ポート情報ダイアログの中身
const PortInformationContent: React.FC<PortInformationProps> = (props) => {

  const userInfoContext = useUserInfoContext();

  const [portTypeState, setPortTypeState] = useState<string>(props.newPort.portType);
  const [businessPartnerIdState, setBusinessPartnerId] = useState<string>(props.newPort.businessPartnerId);
  const [portList, setPortList] = useState<string[]>(props.newPort.destinationPortList);
  const [portNameState, setPortName] = useState<string>(props.newPort.name);
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>(userInfoContext.userInfo);
  const [businessPartnerInfoList, setBusinessPartnerInfoList] = useState<BusinessPartnerInfo[]>([]);

  // エラーメッセージstate
  const [portNameErrorMessage, setPortNameErrorMessage] = useState<string | undefined>(undefined);
  const [userErrorMessage, setUserErrorMessage] = useState<string | undefined>(undefined);

  // ユーザー情報を取得する
  useEffect(() => {
    setUserInfo(userInfoContext.userInfo);
    if (userInfoContext.isError) {
      setUserErrorMessage("ユーザー情報を取得できませんでした。");
    } else {
      setUserErrorMessage(undefined);
    }
  }, [userInfoContext]);

  // ユーザー情報からビスネスパートナーリストの取得
  const getVisibleBusinessPartnerInfoList = (userInfo?: UserInfo): BusinessPartnerInfo[] => {
    let visibleBusinessPartnerInfoList = new Array<BusinessPartnerInfo>();
    const userPartnerInfo = userInfo?.userPartnerInfo;

    if (userPartnerInfo?.partnerKind === PartnerKind.Business) {
      visibleBusinessPartnerInfoList.push(
        userPartnerInfo.partnerInfo as BusinessPartnerInfo
      );
    } else if (userPartnerInfo?.partnerKind === PartnerKind.Operation) {
      for (let visibleBusinessPartnerInfo of (
        userPartnerInfo.partnerInfo as OperationPartnerInfo
      ).visibleBusinessPartnerInfoList) {
        visibleBusinessPartnerInfoList.push(visibleBusinessPartnerInfo);
      }
    }
    return visibleBusinessPartnerInfoList;
  };

  // Chipの削除を押した時
  const chipDelete = (portInfoParam: string) => {
    const portInfoListParam = [
      ...portList.filter((portInfo) => portInfo !== portInfoParam),
    ];
    setPortList(portInfoListParam);
    props.setNewPort({
      ...props.newPort,
      destinationPortList: portInfoListParam,
    });
  };

  // ポート名の入力チェック
  const portNameCheck = (e: string) => {
    const portName = e ? (e as string) : undefined;
    if (portName == null) {
      setPortNameErrorMessage("ポート名を入力してください");
      props.setNewPort({
        ...props.newPort,
        name: "",
        shortName: "",
      });
    } else if (!PortValidator.isNameFormat(portName)) {
      setPortNameErrorMessage("名前はスペース無し日本語、半角英数字のみで入力してください");
      props.setNewPort({
        ...props.newPort,
        name: "",
        shortName: "",
      });
    } else {
      // state更新
      setPortNameErrorMessage(undefined);
      props.setNewPort({
        ...props.newPort,
        name: portName,
        shortName: portName,
      });
    }
  };

  // ポートタイプが受け取りに変わった場合、到着可能ポートを初期化
  useEffect(() => {
    console.log(portTypeState)
    if (portTypeState === PortType.receiver) {
      props.setNewPort({
        ...props.newPort,
        destinationPortList: []
      })
    }
  }, [props.newPort.portType]);

  // すべて入力されているかのチェック
  useEffect(() => {
    if (props.newPort.businessPartnerId !== "" && props.newPort.name !== "") {
      props.setIsNextDisable(false);
    } else {
      props.setIsNextDisable(true);
    }
  }, [props.newPort.businessPartnerId, props.newPort.name]);

  // ビジネスパートナーリストを取得
  useEffect(() => {
    const fetchInitialData = async () => {
      const visibleBusinessPartnerInfoList =
        getVisibleBusinessPartnerInfoList(userInfo);
      setBusinessPartnerInfoList(visibleBusinessPartnerInfoList);
    };
    fetchInitialData();
  }, [userInfo]);

  return (
    <>
      {/* ダイアログタイトル */}
      <DialogTitle id="responsive-dialog-title">
        <FlagIcon style={{ marginRight: 5, marginBottom: -4 }} />
        {`ポート情報登録`}
        <Divider />
      </DialogTitle>

      {/* ダイアログコンテンツ */}
      <DialogContent>
        <Grid container
          spacing={3}
          sx={{
            flexDirection: "column",
            minHeight: 200
          }}
        >
          {/* ユーザーID取得エラー時 */}
          <Grid item>
            {userErrorMessage ? (<Alert severity="error">{userErrorMessage}</Alert>) : null}
          </Grid>

          {/* ポートタイプ */}
          <Grid item style={{ maxWidth: "300px" }}>
            <FormControl variant="filled" size="small" fullWidth>
              <InputLabel>離着陸区分</InputLabel>
              <Select
                value={portTypeState}
                onBlur={(e) => {
                  // state更新
                  props.setNewPort({
                    ...props.newPort,
                    portType: e.target.value as PortType,
                  });
                }}
                onChange={(e) => {
                  setPortTypeState(e.target.value);
                }}
              >
                <MenuItem value={PortType.sender}>送り側</MenuItem>
                <MenuItem value={PortType.receiver}>受け取り側</MenuItem>
                <MenuItem value={PortType.both}>送り側／受け取り側</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* ビジネスパートナー */}
          <Grid item style={{ maxWidth: "300px" }}>
            <FormControl variant="filled" size="small" fullWidth>
              <InputLabel>ビジネスパートナー</InputLabel>
              <Select
                value={businessPartnerIdState}
                onChange={(e) => {
                  if (e.target.value !== businessPartnerIdState) {
                    props.setNewPort({
                      ...props.newPort,
                      businessPartnerId: e.target.value,
                      destinationPortList: []
                    })
                    setBusinessPartnerId(e.target.value);
                    setPortList([])
                  }
                }}
              >
                {businessPartnerInfoList.map((businessPartnerInfo, index: number) => (
                  <MenuItem
                    key={index}
                    value={businessPartnerInfo.businessPartnerId}
                  >
                    {businessPartnerInfo.businessPartnerName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/*到着可能ポート*/}
          {props.allPortList ? (
            portTypeState === PortType.sender || portTypeState === PortType.both ?
              (<Grid item style={{ maxWidth: "300px" }}>
                <FormControl variant="filled" size="small" fullWidth>
                  <InputLabel>到着可能ポート</InputLabel>
                  <Select
                    multiple
                    value={portList}
                    onChange={(e: any) => {
                      setPortList(e.target.value);
                      props.setNewPort({
                        ...props.newPort,
                        destinationPortList: e.target.value,
                      });
                    }}
                    renderValue={(selected) => (
                      <div style={{ display: "flex", flexWrap: "wrap", maxHeight: "100px", overflow: "auto" }}>
                        {selected.map((value, index: number) => (
                          <Tooltip key={index} title={props.allPortList?.find((portInfo) => portInfo.id === value)?.name} arrow>
                            <Chip
                              sx={{ margin: 0.5 }}
                              size="small"
                              key={index}
                              label={props.allPortList?.find((portInfo) => portInfo.id === value)?.name}
                              onDelete={(_) => {
                                chipDelete(value);
                              }}
                              onMouseDown={(event) => {
                                event.stopPropagation();
                              }}
                              style={{ flex: "0 0 auto" }}
                            />
                          </Tooltip>
                        ))}
                      </div>
                    )}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 48 * 4.5,
                          width: 250,
                        },
                      },
                    }}
                  >
                    {props.allPortList.filter((portInfo) =>
                      portInfo.businessPartnerId === businessPartnerIdState &&
                      (portInfo.portType === PortType.both ||
                        portInfo.portType === PortType.receiver)
                    ).map((portInfo: Port, index: number) => (
                      <MenuItem key={index} value={portInfo.id}>
                        <Checkbox
                          checked={
                            portList?.findIndex((checkedPortList) =>
                              checkedPortList === portInfo.id
                            ) !== -1
                          }
                        />
                        <Tooltip title={portInfo.name} arrow>
                          <ListItemText primary={portInfo.name} />
                        </Tooltip>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>) : null
          ) : (
            <Alert severity="error">
              {"到着可能ポートが取得できませんでした"}
            </Alert>
          )}

          {/* ポート名 */}
          <Grid item style={{ maxWidth: "300px" }}>
            <TextField
              size="small"
              variant="filled"
              fullWidth
              label="ポート名"
              placeholder="日本語、半角英数字のみ"
              type="text"
              value={portNameState}
              error={portNameErrorMessage ? true : false}
              helperText={portNameErrorMessage ? portNameErrorMessage : null}
              onChange={(e) => {
                setPortName(e.target.value);
                setPortNameErrorMessage(undefined);
              }}
              onBlur={(e) => {
                portNameCheck(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export default PortInformationContent;