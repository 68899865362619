import { makeStyles } from 'tss-react/mui';
import React, { ChangeEvent, ChangeEventHandler } from "react";
import { Button } from '@mui/material';
import { imageResizeAndConvertBase64 } from '../../common/ImageEditUtil';

type ImageStoreButtonProps = {
    inputFile: File | undefined,
    setImageFile: React.Dispatch<React.SetStateAction<File | undefined>>
    setImageUrl: React.Dispatch<React.SetStateAction<string | undefined>>
    setImageBase64: React.Dispatch<React.SetStateAction<string | undefined>>
}

/**
 * 画像選択ボタン
 * @param props:参照用プロパティ
 */
export const ImageStoreButton: React.FC<ImageStoreButtonProps> = (props) => {
    // TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
    const useStyles = makeStyles()((styles) => ({
        storeButtonCommon: {
            color: 'black'
        },
        storeButton: {
            marginRight: 8,
            color: 'black'
        },
        storeButtonWithFileName: {
            marginTop: 8,
            marginRight: 8,
            color: 'black'
        },
        storeButtonFromCameraWithFileName: {
            marginTop: 8,
            color: 'black'
        },
        errorMessage: {
            marginTop: 8,
            color: 'red',
            whiteSpace: 'pre-wrap'
        }
    }));
    const { classes } = useStyles();
    let [errorMessage, setErrorMessage] = React.useState<string>('');

    let inputImageFile: ChangeEventHandler<HTMLInputElement> = async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files !== null && event.target.files.item(0) !== null) {
            console.log(event.target.files)
            let file = event.target.files.item(0)!;
            console.log(`File.type: ${file.type}`);
            if (file.type === 'image/jpeg' || file.type === 'image/png') {
                props.setImageFile(file);
                const imageData = await imageDataForSend(file);
                props.setImageUrl(imageData?.imageUrl);
                props.setImageBase64(imageData?.imageBase64);
                setErrorMessage('');
            } else {
                setErrorMessage('このファイルは送信できません。\njpg/png形式の画像ファイルのみ送信可能です')
            };
        };
    };

    //input機能付きの場合styledを使った共通化不可
    let storeButton = <label htmlFor="btn-upload">
        <input
            id="btn-upload"
            name="btn-upload"
            style={{ display: 'none' }}
            type="file"
            accept="image/*"
            onChange={inputImageFile} />
        <Button
            className={
                typeof props.inputFile !== 'undefined' ?
                    classes.storeButtonWithFileName :
                    classes.storeButton
            }
            variant="outlined"
            component="span"
        >
            画像を選択
        </Button>
    </label>

    let storeButtonFromCamera = <label htmlFor="btn-upload-from-camera">
        <input
            id="btn-upload-from-camera"
            name="btn-upload-from-camera"
            style={{ display: 'none' }}
            type="file"
            accept="image/*"
            onChange={inputImageFile}
            capture="environment" />
        <Button
            variant="outlined"
            className={
                typeof props.inputFile !== 'undefined' ?
                    classes.storeButtonFromCameraWithFileName :
                    classes.storeButtonCommon
            }
            component="span"
        >
            カメラを起動
        </Button>
        <div className={classes.errorMessage}>
            {errorMessage}
        </div>
    </label>

    /*
    navigateのstateとしてFileオブジェクトを格納できないため、
    予めBase64形式に変換しておく。
    */
    const imageDataForSend = async (imageFile: File) => {
        try {
            if (typeof imageFile !== "undefined") {
                return await imageResizeAndConvertBase64(imageFile);
            } else {
                return undefined;
            }
        } catch (err) {
            console.log(err);
            throw err;
        }
    };

    return (
        <div>
            {storeButton}
            {navigator.userAgent.match(/iPhone|Android/) ? storeButtonFromCamera : null}
        </div>
    );
}