import React, { useEffect, useState } from "react";
import { Alert, Checkbox, Chip, ListItemText, Divider, MenuItem, DialogContent, Select, Tooltip, DialogTitle, TextField, Grid, FormControl, InputLabel, } from "@mui/material";
import FlagIcon from "@mui/icons-material/Flag";
import { Port, PortValidator, PortType } from "adoms-common-lib";

// propsの型を定義
type PortUpdateInformationProps = {
  isOpen: boolean;
  updatePort: Port;
  setUpdatePort: React.Dispatch<React.SetStateAction<Port>>;
  setIsNextDisable: React.Dispatch<React.SetStateAction<boolean>>;
  allPortList?: Port[];
  initialPortType: PortType | undefined;
  editingDestinationPortList: string[];
};

// ポート情報ダイアログの中身
const PortUpdateInformationContent: React.FC<PortUpdateInformationProps> = (props) => {
  const [portNameErrorMessage, setPortNameErrorMessage] = useState<string | undefined>(undefined);

  // Chipの削除を押した時
  const chipDelete = (portInfoParam: string) => {
    const portInfoListParam = [
      ...props.updatePort.destinationPortList.filter((portInfo) => portInfo !== portInfoParam),
    ];
    props.setUpdatePort({
      ...props.updatePort,
      destinationPortList: portInfoListParam,
    });
  };

  // ポート名の入力チェック
  const portNameCheck = (targetValue: string) => {
    const portName = targetValue ? targetValue : undefined;
    if (portName == null) {
      setPortNameErrorMessage("ポート名を入力してください");
      props.setIsNextDisable(true);

    } else if (!PortValidator.isNameFormat(portName)) {
      setPortNameErrorMessage("名前はスペース無し日本語、半角英数字のみで入力してください");
      props.setIsNextDisable(true);

    } else {
      // state更新
      setPortNameErrorMessage(undefined);
      props.setIsNextDisable(false);
    }
  };

  //受け・送り・両方が変わるたびに呼ばれ、必要に応じた処理を行う
  const selectItemFilter = () => {
    let portList: Port[] = [];
    let selectItemList: Port[] = [];

    if (props.allPortList) {
      // ビジネスパートが同じもので切り分け
      portList = props.allPortList.filter((portInfo) =>
        portInfo.businessPartnerId === props.updatePort.businessPartnerId);

      selectItemList = portList.filter((portInfo) =>

        (
          portInfo.portType === PortType.both &&
          // ポートタイプが送り側に変わった場合、自身のポート以外のポートを出す
          !(props.updatePort.portType === PortType.sender && props.updatePort.id === portInfo.id) ||

          // ポートタイプが両方に変わった場合、自身のポートも選択できるように出す
          (props.updatePort.portType === PortType.both && props.updatePort.id === portInfo.id)
        ) ||
        (
          portInfo.portType === PortType.receiver &&
          !(props.updatePort.portType === PortType.sender && props.updatePort.id === portInfo.id)
        ))
    };

    // 最終的な選択可能ポートを選択肢に出す
    return selectItemList.map((portInfo: Port, index: number) => (
      <MenuItem key={index} value={portInfo.id}>
        <Checkbox
          checked={
            props.updatePort.destinationPortList?.findIndex((checkedPortList) =>
              checkedPortList === portInfo.id
            ) !== -1
          }
        />
        <Tooltip title={portInfo.name} arrow>
          <ListItemText primary={portInfo.name} />
        </Tooltip>
      </MenuItem>
    ));
  };

  //選択中のポートタイプが変わったときの到着可能ポートリストを制御する
  const updateDestinationPortList = (newPortType: PortType) => {
    if (newPortType === PortType.receiver) {
      props.setUpdatePort(prev => ({
        ...prev,
        portType: newPortType,
        destinationPortList: [],
      }));
    } else if (newPortType === PortType.sender) {
      // 自分自身にチェックがついていたら外す
      let newDestinationPortList = [...props.editingDestinationPortList];
      newDestinationPortList = newDestinationPortList.filter(value => value !== props.updatePort.id);
      newDestinationPortList = newDestinationPortList.filter(newDestinationPortListIdValue => {
        //相手方のポートを見つける
        const portIndex = props.allPortList!.findIndex(allPortListValue => {
          return newDestinationPortListIdValue == allPortListValue.id
        })
        //相手のポートタイプが送り側だったら、自分の到着可能ポートの一覧から消す
        return props.allPortList![portIndex].portType !== PortType.sender
      })

      props.setUpdatePort(prev => ({
        ...prev,
        portType: newPortType,
        destinationPortList: newDestinationPortList,
      }));
    } else {
      let newDestinationPortList = [...props.editingDestinationPortList];
      if (!newDestinationPortList.some(value => value === props.updatePort.id)) {
        newDestinationPortList.push(props.updatePort.id);
      }

      props.setUpdatePort(prev => ({
        ...prev,
        portType: newPortType,
        destinationPortList: newDestinationPortList,
      }));
    }
  };

  return (
    <>
      {/* ダイアログタイトル */}
      <DialogTitle id="responsive-dialog-title">
        <FlagIcon style={{ marginRight: 5, marginBottom: -4 }} />
        {`ポート情報更新`}
        <Divider />
      </DialogTitle>

      {/* ダイアログコンテンツ */}
      <DialogContent>
        <Grid container
          spacing={3}
          sx={{
            flexDirection: "column",
            minHeight: 200
          }}
        >

          {/* ビジネスパートナー */}
          <Grid item style={{ maxWidth: "300px" }}>
            <TextField
              disabled
              size="small"
              variant="filled"
              fullWidth
              label="ビジネスパートナー"
              type="text"
              value={props.updatePort.businessPartnerId}
            />
          </Grid>

          {/* ポートタイプ */}
          <Grid item style={{ maxWidth: "300px" }}>
            <FormControl variant="filled" size="small" fullWidth>
              <InputLabel>離着陸区分</InputLabel>
              <Select
                value={props.updatePort.portType || ""}
                onChange={(e) => {
                  const newPortType = e.target.value as PortType;
                  updateDestinationPortList(newPortType);
                }}
              >
                <MenuItem value={PortType.sender}>送り側</MenuItem>
                <MenuItem value={PortType.receiver}>受け取り側</MenuItem>
                <MenuItem value={PortType.both}>送り側／受け取り側</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* 到着可能ポート */}
          {props.allPortList ? (
            props.updatePort.portType === PortType.sender || props.updatePort.portType === PortType.both ?
              (<Grid item style={{ maxWidth: "300px" }}>
                <FormControl variant="filled" size="small" fullWidth>
                  <InputLabel>到着可能ポート</InputLabel>
                  <Select
                    multiple
                    value={props.updatePort.destinationPortList}
                    onChange={(e: any) => {
                      //編集されたらデータは変更できるようにする
                      props.setUpdatePort({
                        ...props.updatePort,
                        destinationPortList: e.target.value,
                      });
                      console.log("到着可能ポート", props.updatePort.destinationPortList);
                    }}
                    renderValue={(selected) => (
                      <div style={{ display: "flex", flexWrap: "wrap", maxHeight: "100px", overflow: "auto" }}>
                        {selected.map((value: string, index: number) => (
                          <Tooltip key={index} title={props.allPortList?.find((portInfo) => portInfo.id === value)?.name} arrow>
                            <Chip
                              sx={{ margin: 0.5 }}
                              size="small"
                              key={index}
                              label={props.allPortList?.find((portInfo) => portInfo.id === value)?.name}
                              onDelete={(_) => {
                                chipDelete(value);
                              }}
                              onMouseDown={(event) => {
                                event.stopPropagation();
                              }}
                              style={{ flex: "0 0 auto" }}
                            />
                          </Tooltip>
                        ))}
                      </div>
                    )}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 48 * 4.5,
                          width: 250,
                        },
                      },
                    }}
                  >
                    {selectItemFilter()}
                  </Select>
                </FormControl>
              </Grid>) : null
          ) : (
            <Alert severity="error">
              {"到着可能ポートが取得できませんでした"}
            </Alert>
          )}

          {/* ポート名 */}
          <Grid item style={{ maxWidth: "300px" }}>
            <TextField
              size="small"
              variant="filled"
              fullWidth
              label="ポート名"
              placeholder="日本語、半角英数字のみ"
              type="text"
              value={props.updatePort.name}
              error={portNameErrorMessage ? true : false}
              helperText={portNameErrorMessage ? portNameErrorMessage : null}
              onChange={(e) => {
                setPortNameErrorMessage(undefined);
                props.setUpdatePort({
                  ...props.updatePort,
                  name: e.target.value,
                });
              }}
              onBlur={(e) => {
                portNameCheck(e.target.value);
                console.log("ポート名", props.updatePort.name);
              }}
            />
          </Grid>

          {/* 環境センサーID */}
          <Grid item style={{ maxWidth: "300px" }}>
            <TextField
              size="small"
              variant="filled"
              fullWidth
              label="環境センサーID - 任意"
              type="text"
              value={props.updatePort.envSensorDeviceID ?? ""}
              onChange={(e) => {
                props.setUpdatePort({
                  ...props.updatePort,
                  envSensorDeviceID: e.target.value,
                });
              }}
            />
          </Grid>

          {/* 備考 */}
          <Grid item style={{ maxWidth: "300px" }}>
            <TextField
              multiline
              rows={4}
              size="small"
              variant="filled"
              fullWidth
              label="備考 - 任意"
              type="text"
              value={props.updatePort.remarks ?? ""}
              onChange={(e) => {
                props.setUpdatePort({
                  ...props.updatePort,
                  remarks: e.target.value,
                });
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export default PortUpdateInformationContent;