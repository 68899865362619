/**
 * 位置情報データを表すデータ型
 */
export interface GeoInformation {
    flightId: string,
    droneName: string,
    latitude: number,
    longitude: number,
    altitude: number,
    sendTime: string
}

/**
 * MAPスタイル
 */
export const MapStyle = {
    /**
     * 地形
     */
    neutral: "neutral",
    /**
     * 衛星写真
     */
    satellite: "satellite"
} as const;
/**
 * ポートタイプ
 */
export type MapStyle = typeof MapStyle[keyof typeof MapStyle];