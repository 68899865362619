import LinkIcon from '@mui/icons-material/Link';
import { Alert, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import { Display, MapURLInformation } from 'adoms-common-lib';
import React from 'react';


type MapURLListTableProps = {
    mapURLInfoList: MapURLInformation[]
    setSnackBarOpen: React.Dispatch<React.SetStateAction<boolean>>
};

/**
 * マップURL情報リストテーブル表示用コンポーネント
 * MapURLInformation側SaaS対応完了次第相手方と統合する
 * @param props:参照用プロパティ
 */
export const MapURLListTable: React.FC<MapURLListTableProps> = (props) => {

    /**
     * TableBodyを表示する
     * @param mapURLInfo 
     * @returns 
     */
    const displayTableBody = (mapURLInfo: MapURLInformation) => {
        const createDateTime = Display.getDateTimeStringFromISO8601(mapURLInfo.createDateTime);
        const expirationDateTime = Display.getDateTimeStringFromISO8601(mapURLInfo.expirationDateTime);
        const handleCopyToClipboard = async () => {
            if (mapURLInfo.url) {
                await global.navigator.clipboard.writeText(mapURLInfo.url);
                props.setSnackBarOpen(true);
            }
        };

        return (
            <TableRow key={mapURLInfo.url}>
                <TableCell>
                    <Tooltip title="URLをコピー">
                        <IconButton
                            aria-label="close"
                            color="primary"
                            size="small"
                            onClick={
                                () => handleCopyToClipboard()
                            }
                        >
                            <LinkIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell>
                    {mapURLInfo.urlId}
                </TableCell>
                <TableCell>{expirationDateTime}</TableCell>
                <TableCell>{createDateTime}</TableCell>
                <TableCell>{mapURLInfo.issueUser.name}</TableCell>
                <TableCell>{mapURLInfo.businessPartnerId}</TableCell>
                <TableCell>{mapURLInfo.remarks}</TableCell>
            </TableRow>
        );
    };
    return (
        <Table sx={{ minWidth: 650 }} aria-label="map-url-info-table">
            <TableHead>
                <TableRow>
                    <TableCell >
                        <LinkIcon sx={{ marginLeft: "5px", marginTop: "6px" }} fontSize="small" />
                    </TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell>有効期限</TableCell>
                    <TableCell>発行日時</TableCell>
                    <TableCell>発行者</TableCell>
                    <TableCell>ビジネスパートナーID</TableCell>
                    <TableCell>備考</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    props.mapURLInfoList.length !== 0 ?
                        props.mapURLInfoList.map((mapURLInfo, i) => (
                            displayTableBody(mapURLInfo)
                        ))
                        :
                        <TableRow>
                            <TableCell colSpan={6}>
                                <Alert severity="info">URLがありません。</Alert>
                            </TableCell>
                        </TableRow>
                }
            </TableBody>
        </Table>
    );
};