import { Slider, SliderValueLabelProps, Tooltip, Typography } from "@mui/material";
import dayjs from 'dayjs';
import React from 'react';
import { changeMinutes10MinuteIncrements } from "../../common/weatherUtil";

type WeatherTimeSliderProps = {
    setUpdateWeatherInfo: React.Dispatch<React.SetStateAction<boolean>>
    setDisplayWeatherInfoDayJs: React.Dispatch<React.SetStateAction<dayjs.Dayjs | undefined>>
    displayWeatherInfoDayJs: dayjs.Dayjs | undefined
};

function ValueLabelComponent(props: SliderValueLabelProps) {
    const { children, value } = props;

    return (
        <Tooltip enterTouchDelay={0} placement="top" title={<Typography variant="subtitle2">{value}</Typography>}>
            {children}
        </Tooltip>
    );
};

export const WeatherTimeSlider: React.FC<WeatherTimeSliderProps> = (props) => {
    const sliderTimeValuetext = (value: number) => {
        handleCalculateDiff();
        const referenceDayJs = changeMinutes10MinuteIncrements(dayjs());
        const sliderDayJs = referenceDayJs.add(value, "minute");
        return sliderDayJs?.format("HH:mm");
    };

    const handleTimeSliderChange = (newValue: number | number[]) => {
        const referenceDayJs = changeMinutes10MinuteIncrements(dayjs());
        const sliderDayJs = referenceDayJs.add(newValue as number, "minute");
        props.setDisplayWeatherInfoDayJs(sliderDayJs);
    };

    const handleTimeSliderChangeCommited = (newValue: number | number[]) => {
        const referenceDayJs = changeMinutes10MinuteIncrements(dayjs());
        const sliderDayJs = referenceDayJs.add(newValue as number, "minute");
        props.setDisplayWeatherInfoDayJs(sliderDayJs);
        props.setUpdateWeatherInfo(true);
    };

    const handleCalculateDiff = () => {
        const referenceDayJs = changeMinutes10MinuteIncrements(dayjs());
        return props.displayWeatherInfoDayJs?.diff(referenceDayJs, "minute");
    };

    return (
        <Slider
            size='small'
            value={handleCalculateDiff()}
            marks
            defaultValue={0}
            step={10}
            valueLabelDisplay="auto"
            max={60}
            valueLabelFormat={sliderTimeValuetext}
            onChange={(event, newValue) => handleTimeSliderChange(newValue)}
            onChangeCommitted={(event, newValue) => handleTimeSliderChangeCommited(newValue)}
            slots={{
                valueLabel: ValueLabelComponent
            }}
            sx={{ width: "250px" }}
        />
    )
}