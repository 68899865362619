import ImageIcon from '@mui/icons-material/Image';
import ScaleIcon from '@mui/icons-material/Scale';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, TextField, Typography } from '@mui/material';
import { OrderInfo } from 'adoms-common-lib';
import React, { useEffect } from "react";
import { NumericFormat } from 'react-number-format';
import { APIConnector } from '../../connector/APIConnector';
import { ImageStoreButton } from '../atoms/ImageStoreButton';
import { LoadingMark } from '../atoms/LoadingMark';

type OrderInfoEditDiglogProps = {
    isOrderInfoEditDialogOpen: boolean
    setOrderInfoEditDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
    displayOrderInfo: OrderInfo
    setSavedOrderInfo: React.Dispatch<React.SetStateAction<boolean>>
};

interface NumberFormatCustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
    props: NumberFormatCustomProps,
    ref,
) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            inputMode='decimal'
            valueIsNumericString
            allowNegative={false}
            suffix="g"
        />
    );
});

export const OrderInfoEditDiglog: React.FC<OrderInfoEditDiglogProps> = (props) => {

    const [weight, setWeight] = React.useState<number | undefined>(props.displayOrderInfo?.goods[0].weight);
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [isDisplayLoadingMark, setDisplayLoadingMark] = React.useState<boolean>(false);
    const [seleectedOrderId, setSelectedOrderId] = React.useState<string>();
    const [imageFile, setImageFile] = React.useState<File | undefined>(undefined);
    const [imageUrl, setImageUrl] = React.useState<string | undefined>(undefined);
    const [imageBase64, setImageBase64] = React.useState<string | undefined>(undefined);

    useEffect(() => {
        setSelectedOrderId(props.displayOrderInfo.orderID);
        if (seleectedOrderId !== props.displayOrderInfo.orderID) {
            setDefaultValue();
        };
    }, [props.displayOrderInfo]);

    const handleOrderInfoEditDialogClose = () => {
        setDefaultValue();
        props.setOrderInfoEditDialogOpen(false);
    };

    const setDefaultValue = () => {
        setErrorMessage(undefined);
        setWeight(props.displayOrderInfo?.goods[0].weight);
        setImageFile(undefined);
        setImageUrl(undefined);
        setImageBase64(undefined);
    };

    const disabledButton = (): boolean => {
        if (props.displayOrderInfo.goods[0].weight === weight
            && !imageBase64) {
            return true;
        } else {
            return false;
        }
    };

    const handleSaveOrderInfo = async () => {
        if (props.displayOrderInfo) {
            setDisplayLoadingMark(true);
            setErrorMessage(undefined);

            const updateOrderInfo = structuredClone(props.displayOrderInfo);
            if (weight) {
                updateOrderInfo.goods[0].weight = weight;
            };

            let apiConnector: APIConnector = APIConnector.instance;
            await apiConnector.putOrderChange(updateOrderInfo, imageBase64).then(async value => {
                props.setSavedOrderInfo(true);
                props.setOrderInfoEditDialogOpen(false);
                setDisplayLoadingMark(false);
            }).catch(error => {
                console.log(error);
                setErrorMessage("配送情報を更新できませんでした。");
                setDisplayLoadingMark(false);
            });
        };
    };

    const handleChangeWeight = (event: any) => {
        const inputGoodWeightParam = Number(event.target.value);
        setWeight(inputGoodWeightParam);
    };

    return (
        <Dialog
            open={props.isOrderInfoEditDialogOpen}
            onClose={handleOrderInfoEditDialogClose}
        >
            <DialogTitle id="dialog-title">
                配送情報変更
            </DialogTitle>
            <DialogContent sx={{ paddingBottom: 0 }}>
                <Grid container spacing={2} direction={"column"}>
                    {errorMessage ?
                        <Grid item xs={12}>
                            <Alert severity="error">{errorMessage}</Alert>
                        </Grid> : undefined
                    }
                    <Grid item container spacing={2} direction={"row"} flexWrap="nowrap" xs>
                        <Grid item>
                            <ScaleIcon sx={{ color: "#797979" }} />
                        </Grid>
                        <Grid item container direction={"column"} spacing={1}>
                            <Grid item>
                                <Typography color="textSecondary">重量</Typography>
                            </Grid>
                            <Grid item>
                                <TextField
                                    onChange={(e: any) => handleChangeWeight(e)}
                                    name="weight"
                                    id="formatted-numberformat-input"
                                    value={weight}
                                    InputProps={{
                                        inputComponent: NumberFormatCustom as any,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2} direction={"row"} flexWrap="nowrap" xs>
                        <Grid item>
                            <ImageIcon sx={{ color: "#797979" }} />
                        </Grid>
                        <Grid item container direction={"column"} spacing={1}>
                            <Grid item>
                                <Typography color="textSecondary">注文内容</Typography>
                            </Grid>
                            <Grid item container direction={"column"} spacing={1}>
                                <Grid item>
                                    <ImageStoreButton
                                        inputFile={imageFile}
                                        setImageFile={setImageFile}
                                        setImageUrl={setImageUrl}
                                        setImageBase64={setImageBase64}
                                    />
                                </Grid>
                                {
                                    imageFile ?
                                        <Grid item>
                                            <Typography variant='subtitle2' color="primary">
                                                {imageFile.name}
                                            </Typography>
                                        </Grid> : undefined
                                }
                                <Grid item>
                                    <Paper elevation={0}>
                                        <img
                                            id="receiptImage"
                                            src={imageUrl}
                                            style={
                                                navigator.userAgent.match(/iPhone|Android.+Mobile/) ?
                                                    { width: '100%' } :
                                                    { width: '50%' }
                                            } />
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <LoadingMark isDisplayLoadingMark={isDisplayLoadingMark} />
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={disabledButton()}
                    onClick={setDefaultValue}>
                    リセット
                </Button>
                <Button
                    color="secondary"
                    disabled={disabledButton()}
                    onClick={handleSaveOrderInfo}>
                    登録
                </Button>
            </DialogActions>
        </Dialog >
    )
}